import { graphql, Link } from "gatsby";
import React from "react";
import InfoHeader from "component/InfoHeader/InfoHeader";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import { useSiteUrl } from "utils";
import { css } from "@emotion/react";
import ScrollButton from "component/ScrollButton/ScrollButton";

const CategoriesCSS = {
  self: css`
    flex-grow: 1;
  `,
  posts: css`
    list-style-type: none;

    > div {
      font-weight: bold;
      font-size: var(--font-size-md);
      padding: 10px 0 5px;
    }
  `,
  post: css`
    padding: 5px;
    border-bottom: 1px solid var(--color-border);

    @media screen and (min-width: 576px) {
      display: flex;
      justify-content: space-between;
    }

    a {
      position: relative;
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }
    .tags {
      display: none;
      flex-shrink: 0;
      text-transform: lowercase;
      @media screen and (min-width: 576px) {
        margin-left: 20px;
        display: block;
      }
    }
  `,
};

export default function Categories(props) {
  const {
    posts: { nodes: posts },
  } = props.data;

  const nameTag = props.pageContext.name;
  const canonicalUrl = `${useSiteUrl()}${props.location.pathname}`;

  const arrPosts = (function (posts) {
    let arr = [];

    try {
      const groupedPosts = posts.reduce((group, post) => {
        return {
          ...group,
          [post.date]: [...(group[post.date] || []), post],
        };
      }, {});

      arr = Object.entries(groupedPosts).map(([date, posts], i) => {
        const formatDate = new Date(date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });

        return [
          <div key={i}>{formatDate}</div>,
          posts.map((post) => (
            <li key={post.id} css={CategoriesCSS.post}>
              <Link to={`/${post.url}/`}>{post.title}</Link>
              <span className="tags">
                {post.tags
                  .map((tag) => "#" + tag.name + ", ")
                  .join("")
                  .slice(0, -2)}
              </span>
            </li>
          )),
        ];
      });
    } catch (error) {
      arr = [];
    }

    return arr;
  })(posts);

  return (
    <Layout>
      <Seo title={nameTag} canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <div css={CategoriesCSS.self}>
        <div className="wrapper">
          <InfoHeader title={nameTag} subtitle="TAGGED POSTS" />
          <ul css={CategoriesCSS.posts}>{arrPosts}</ul>
        </div>
      </div>
      <ScrollButton />
      <SidebarRight />
    </Layout>
  );
}
export const query = graphql`
  query getCategory($url: String) {
    posts: allContentfulPosts(
      sort: { fields: date, order: DESC }
      filter: { tags: { elemMatch: { url: { eq: $url } } } }
    ) {
      nodes {
        title
        url
        id
        tags {
          id
          name
          url
        }
        date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;
